import { FC } from 'react';
import IconProps from './IconProps';

// LEGACY icon, duplicated by `InventoryIcon`
// You probably want "MultiCheckboxIcon"
const CheckListIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="currentColor"
    >
      <path
        d="M10.5435 2.0249C10.0023 2.0249 9.56348 2.46365 9.56348 3.0049C9.56348 3.54612 10.0023 3.9849 10.5435 3.9849H16.6635C17.2047 3.9849 17.6435 3.54612 17.6435 3.0049C17.6435 2.46365 17.2047 2.0249 16.6635 2.0249H10.5435ZM10.5435 9.21155C10.0023 9.21155 9.56348 9.65033 9.56348 10.1916C9.56348 10.7328 10.0023 11.1716 10.5435 11.1716H16.6635C17.2047 11.1716 17.6435 10.7328 17.6435 10.1916C17.6435 9.65033 17.2047 9.21155 16.6635 9.21155H10.5435ZM10.5435 16.3982C10.0023 16.3982 9.56348 16.837 9.56348 17.3782C9.56348 17.9195 10.0023 18.3582 10.5435 18.3582H16.6635C17.2047 18.3582 17.6435 17.9195 17.6435 17.3782C17.6435 16.837 17.2047 16.3982 16.6635 16.3982H10.5435Z"
        fill="currentColor"
      />
      <path
        d="M5.69558 0.498744C5.85542 0.300662 6.08605 0.172538 6.33868 0.141472C6.53561 0.115884 6.73566 0.15072 6.91234 0.241362C7.08903 0.332004 7.23401 0.47418 7.32809 0.649058C7.42218 0.823935 7.46092 1.02326 7.43919 1.22064C7.41746 1.41803 7.33628 1.60417 7.20641 1.75439L3.93974 5.67439C3.85223 5.7793 3.74375 5.86474 3.62127 5.92525C3.49878 5.98577 3.365 6.02001 3.2285 6.02578C3.09201 6.03155 2.95581 6.00871 2.82866 5.95875C2.70151 5.90878 2.58621 5.83279 2.49016 5.73564L0.530158 3.77564C0.429904 3.68677 0.348933 3.57828 0.292248 3.45689C0.235563 3.3355 0.204366 3.20379 0.200586 3.06987C0.196805 2.93595 0.220521 2.80268 0.270267 2.67828C0.320014 2.55389 0.394736 2.44102 0.489818 2.34663C0.584899 2.25225 0.698321 2.17835 0.823079 2.12953C0.947837 2.0807 1.08128 2.05797 1.21517 2.06273C1.34906 2.0675 1.48055 2.09966 1.60151 2.15724C1.72248 2.21482 1.83036 2.29659 1.91849 2.39749L3.11285 3.59189L5.69558 0.498744Z"
        fill="currentColor"
      />
      <path
        d="M5.69558 7.68539C5.85542 7.48731 6.08605 7.35918 6.33868 7.32812C6.53561 7.30253 6.73566 7.33737 6.91234 7.42801C7.08903 7.51865 7.23401 7.66083 7.32809 7.8357C7.42218 8.01058 7.46092 8.2099 7.43919 8.40729C7.41746 8.60468 7.33628 8.79081 7.20641 8.94104L3.93974 12.861C3.85223 12.9659 3.74375 13.0514 3.62127 13.1119C3.49878 13.1724 3.365 13.2067 3.2285 13.2124C3.09201 13.2182 2.95581 13.1954 2.82866 13.1454C2.70151 13.0954 2.58621 13.0194 2.49016 12.9223L0.530158 10.9623C0.429904 10.8734 0.348933 10.7649 0.292248 10.6436C0.235563 10.5222 0.204366 10.3904 0.200586 10.2565C0.196805 10.1226 0.220521 9.98932 0.270267 9.86493C0.320014 9.74053 0.394736 9.62766 0.489818 9.53328C0.584899 9.4389 0.698321 9.365 0.823079 9.31617C0.947837 9.26734 1.08128 9.24461 1.21517 9.24938C1.34906 9.25415 1.48055 9.28631 1.60151 9.34389C1.72248 9.40146 1.83036 9.48323 1.91849 9.58414L3.11285 10.7785L5.69558 7.68539Z"
        fill="currentColor"
      />
      <path
        d="M5.69558 14.8721C5.85542 14.674 6.08605 14.5459 6.33868 14.5148C6.53561 14.4892 6.73566 14.524 6.91234 14.6147C7.08903 14.7053 7.23401 14.8475 7.32809 15.0224C7.42218 15.1972 7.46092 15.3966 7.43919 15.594C7.41746 15.7913 7.33628 15.9775 7.20641 16.1277L3.93974 20.0477C3.85223 20.1526 3.74375 20.238 3.62127 20.2986C3.49878 20.3591 3.365 20.3933 3.2285 20.3991C3.09201 20.4049 2.95581 20.382 2.82866 20.3321C2.70151 20.2821 2.58621 20.2061 2.49016 20.109L0.530158 18.149C0.429904 18.0601 0.348933 17.9516 0.292248 17.8302C0.235563 17.7088 0.204366 17.5771 0.200586 17.4432C0.196805 17.3093 0.220521 17.176 0.270267 17.0516C0.320014 16.9272 0.394736 16.8143 0.489818 16.7199C0.584899 16.6256 0.698321 16.5517 0.823079 16.5029C0.947837 16.454 1.08128 16.4313 1.21517 16.4361C1.34906 16.4408 1.48055 16.473 1.60151 16.5306C1.72248 16.5882 1.83036 16.6699 1.91849 16.7708L3.11285 17.9652L5.69558 14.8721Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckListIcon;
